.option-container {
	background-color: white;
	border-radius: 0px 0px 10px 10px;
	overflow-y: auto;
	max-height: 40vh;
	display: flex;
	flex-direction: column;
	box-shadow: 0px 0px 8px #ddd;
	position: absolute !important;
	right: 6px;
	left: 6px;
	text-indent: 1rem;
	z-index: 10;
}

.option-container-with-button {
	background-color: white;
	border-radius: 0px 0px 10px 10px;
	overflow-y: auto;
	max-height: 40vh;
	display: flex;
	flex-direction: column;
	box-shadow: 0px 0px 8px #ddd;
	position: absolute !important;
	right: 6px;
	left: 6px;
	text-indent: 1rem;
	z-index: 10;
}

.option-item {
	padding: 5px 20px;
	cursor: pointer;
}

.no-options {
	padding: 5px 20px;
	cursor: pointer;
	color: #b01a2e;
}

.option-item:hover {
	background-color: #efefef;
}

.option-add {
	padding: 5px 20px;
	color: #0296f2;
	cursor: pointer;
}
