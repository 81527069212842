.input-date::-webkit-calendar-picker-indicator {
	margin-right: 10px;
}

.input-date {
	color: #314866;
}

.input-date::-webkit-datetime-edit-day-field {
	color: #314866;
	margin-left: 10px;
}

.input-date::-webkit-datetime-edit-month-field {
	color: #314866;
}

.input-date::-webkit-datetime-edit-year-field {
	color: #314866;
}
