@import url('https://netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.css');

table {
	width: 100%;
}

th {
	font-family: 'DM Sans', sans-serif !important;
	font-weight: normal !important;
	font-size: 14px !important;
}

.product-info {
	max-width: 200px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

table.fold-table > tbody > tr.view td:nth-child(2),
table.fold-table > tbody > tr.view th:nth-child(2) {
	cursor: pointer;
	position: relative;
	padding-left: 24px;
}

table.fold-table > tbody > tr.view td:nth-child(2):before,
table.fold-table > tbody > tr.view th:nth-child(2):before {
	position: absolute;
	top: 50%;
	left: -12px;
	margin-top: -13px;
	color: #a3aed0;
	content: url('../../../../../../assets/images/icons/icon-caret-right.svg');
	transition: all 0.3s ease;
}

table.fold-table > tbody > tr.view.open td:nth-child(2):before,
table.fold-table > tbody > tr.view.open th:nth-child(2):before {
	transform: rotate(90deg);
	margin-top: -14px;
	color: #314866;
}

table.fold-table > tbody > tr.fold {
	display: none;
}

table.fold-table > tbody > tr.fold.open {
	display: table-row;
}

.white-space {
	white-space: normal;
}

.width-300 {
	width: 300px;
}
