@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
body {
	font-family: 'DM Sans', sans-serif;
}

option {
	color: black;
}

.filter-bar.familia option {
	width: 60px;
	font-size: 1.2em;
	padding: 10px 0;
	text-align: center;
	margin-right: 20px;
	display: inline-block;
	cursor: pointer;
	border: rgb(204, 204, 0) solid 1px;
	border-radius: 5px;
	color: rgb(204, 204, 0);
}

/* somente em telas menores q 1000px */
@media screen and (max-width: 1400px) {
	/* .duracao-12 .input-tr {
  
    padding-inline-start: 5px;
    padding-inline-end: 5px;
    height: 50%;
    border-radius: 5px;
    text-align: center;
    font-size: 12px;
  
  } */
}

.duracao-12 th {
	padding-top: 20px;
}

.duracao-12 p {
	padding-left: 0px;
	text-align: center;
}

.duracao-12 .fix:not(.fix-last) p {
	background-color: white;
}
.duracao-12 select {
	/* height: 33px; */
	font-size: 12px;
	background-color: white;
}

.duracao-12 table {
	table-layout: fixed;
	width: 100%;
	/* *margin-left: -100px; ie7 */
}
.duracao-12 td,
th {
	vertical-align: top;
	padding: 10px;
	width: 100px;
}
.duracao-12 .fix {
	position: absolute;
	/* *position: relative; ie7 */
	margin-left: -145px;
	width: 145px;
	z-index: 100;
	height: 61px;
}

/* second element with .fix inside a tr using pseudo class */

.duracao-12 .outer {
	position: relative;
}
.duracao-12 .inner {
	overflow-x: scroll;
	overflow-y: visible;
	width: calc(100vw - 832px);
	margin-left: 130px;
}

.duracao-12 tr > .fix-last {
	right: 0px;
	width: 150px;
}
.duracao-12.q-3 tr > .fix-last {
	width: 100px;
}

.duracao-12 tr > .fix-last + .fix-last:not(:last-child) {
	right: 100px;
}

.duracao-12.q-3 tr > .fix-last + .fix-last:last-child {
	right: 200px;
}

.duracao-12 tr > .fix-last + .fix-last:last-child {
	right: 150px;
}

.duracao-12 ::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}

/* Track */
.duracao-12 ::-webkit-scrollbar-track {
	box-shadow: inset 0 0 2px grey;
	border-radius: 10px;
}

/* Handle */
.duracao-12 ::-webkit-scrollbar-thumb {
	background: #fc8181;
	border-radius: 10px;
}

/* Handle on hover */
.duracao-12 ::-webkit-scrollbar-thumb:hover {
	background: #b30000;
}

.scroll-red:hover,
.scroll-red:focus,
.scroll-red:active {
	opacity: 0.6 !important;
}

.amplify-button[data-variation='primary'] {
	background: linear-gradient(to right, #065688, #bbcfdc);
	border-style: none;
}

.amplify-button[data-variation='primary']:disabled {
	opacity: 0.4;
	color: #ffffff;
	background: #314866;
}

/* .amplify-button[data-variation='primary']:hover {
  background: linear-gradient(
    to right,
    #065688,
    #BBCFDC
  );

} */

/* Influencia em outras telas que não tem o template padrão da easy360, verificar aonde foi utilizado e porque criado globalmente essa estilização pra plataforma toda!!! */
/* #chakra-toast-portal {
  min-height: 100vh;
} */
