.inputDecimal {
	width: 100%;
	height: 44px;
	border-radius: 10px;
	border: 1px solid #e2e8f0;
	font-size: 1rem;
	color: #4a5568;
	outline: none;
	transition: all 0.2s;
	padding-left: 16px !important;
}

.inputDecimal:focus {
	border: 2px solid #3965ff !important;
	box-shadow: 0 0 0 1px #3965ff !important;
}

.inputDecimalException {
	width: 100%;
	height: 44px;
	border-radius: 16px;
	font-size: 1rem;
	outline: none;
	transition: all 0.2s;
	padding-left: 16px !important;
}

.exception {
	border: 1px solid #1ba118;
	color: #1ba118;
}
.exception:focus {
	border: 2px 1px solid #1ba118 !important;
	box-shadow: 0 0 0 1px #1ba118 !important;
}
.exception:disabled {
	background-color: white;
}

.exceptionBlank {
	border: 1px solid #e2e8f0;
	color: #8f9bba;
}

.exceptionBlank:focus {
	border: 2px solid #e2e8f0 !important;
	box-shadow: 0 0 0 1px #e2e8f0 !important;
}

.exceptionEdit {
	border: 1px solid #3965ff;
	color: #3965ff;
}

.exceptionEdit:focus {
	border: 2px 1px solid #3965ff !important;
	box-shadow: 0 0 0 1px #3965ff !important;
}
