.input-datetime::-webkit-calendar-picker-indicator {
	/* display: none; */
	margin-right: -6px;
}

.input-datetime {
	color: #314866;
}

.input-datetime::-webkit-datetime-edit-day-field {
	color: #314866;
}

.input-datetime::-webkit-datetime-edit-month-field {
	color: #314866;
}

.input-datetime::-webkit-datetime-edit-year-field {
	color: #314866;
}

.input-datetime::-webkit-datetime-edit-hour-field {
	color: #314866;
}

.input-datetime::-webkit-datetime-edit-minute-field {
	color: #314866;
}
