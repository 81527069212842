.apexcharts-custom-tooltip {
	display: flex;
	flex-direction: column;
}

.apexcharts-tooltip-title {
	background: #000;
	padding: 50px;
	border-radius: 5px;
	color: #fff;
	margin-bottom: 10px;
}

.info-group {
	margin-bottom: 20px;

	span {
		font-weight: bold;
		margin-left: 5px;
	}
}

.break-word-span {
	white-space: normal;
}

.date-container {
	margin-left: 24px;
	font-style: italic;
}

.bullet-container {
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;
}

.suggested-stock-total-container {
	display: flex;
	flex-direction: column;
	margin-left: 24px;
}

.bullet {
	display: inline-block;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: var(--bullet-color, #fff);
	margin-right: 5px;
}
