.input-time::-webkit-calendar-picker-indicator {
	/* display: none; */
	margin-right: -8px;
}

.input-time {
	color: #314866;
}

.input-time::-webkit-datetime-edit-hour-field {
	color: #314866;
}

.input-time::-webkit-datetime-edit-minute-field {
	color: #314866;
}
