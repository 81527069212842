.select {
	max-height: 160px;
	overflow: auto;
	margin: 0;
	border: 1px solid #e2e8f0;
	border-radius: 0 0 10px 10px;
	height: 160px;
	width: 379px;
	overflow-x: hidden;
}

.select-no-result {
	display: flex;
	border-radius: 10px;
	margin-top: 10px;
}

.select::-webkit-scrollbar {
	width: 18px;
	border-radius: 0 0 10px 0;
}

.select::-webkit-scrollbar-button {
	display: none;
}

.select::-webkit-scrollbar-thumb {
	background-color: #e2e8f0;
	width: 2px;
	height: 50px;
	border: 6px solid white;
	border-radius: 10px;
}

.select::-webkit-scrollbar-track {
	margin-top: 1px;
	margin-bottom: 1px;
	border-radius: 0 0 10px 0;
}

.button-link {
	padding: 0 0 10px 15px;
	font-size: 12px;
	border-radius: 0 0 0 10px;
	text-align: left;
	color: #0296f2;
	width: 259px;
}

.button-link-static {
	margin: 10px 0 0 0;
	background-color: white;
	width: 379px;
	width: 100%;
	z-index: 1;
	padding: 10px 15px;
	border: 1px solid #e2e8f0;
	border-radius: 10px 10px 0 0;
	border-bottom: none;
	outline: none;
}

u:hover {
	opacity: 0.8;
}

label {
	display: flex;
	font-size: 12px;
}

.checkbox-input {
	margin: 0 10px;
	position: relative;
	cursor: pointer;
	accent-color: #314866;
	outline: none;
}
