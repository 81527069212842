.produto-com-roteiro {
	background-color: #ceffce !important;
	color: #108210 !important;
}
.tabela-obrigatoria {
	background-color: #f0243e1a !important;
	color: #b01a2e !important;
}
.tabela-table-obrigatoria {
	color: #b01a2e !important;
}
.tabela-table-obrigatoria-table {
	color: #b01a2e !important;
	font-size: 14px !important;
}

::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

::-webkit-scrollbar-thumb {
	background-color: rgb(194, 201, 225);
	border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
	background-color: rgb(177, 187, 221);
}

.tooltip {
	position: relative;
	display: inline-block;
}

.tooltip .tooltiptext {
	visibility: hidden;
	width: auto;
	background-color: #555;
	color: #fff;
	text-align: center;
	padding: 5px;
	border-radius: 6px;
	position: absolute;
	z-index: 1;
	bottom: 65%;
	left: 50%;
	margin-left: -60px;
}
.centered-text-number-input {
	text-align: center !important;
	padding-left: 7px !important;
}

.tooltip:hover .tooltiptext {
	visibility: visible;
}
